import React from 'react'
import { Link } from 'gatsby'
import {
    Container, Row, Col,
    Pagination, PaginationItem, PaginationLink
  } from 'reactstrap'

const BlogPagination = props => {
  return (
    <>
      {props.context.numberOfPages > 1 && (
          <Container className="pagination_wrapper">
              <Row>
                  <Col className="text-center">

                    <Pagination aria-label="Page navigation">

                        {props.context.previousPagePath && (
                            <PaginationItem>
                                <Link to={`${props.context.previousPagePath}`}>
                                    <PaginationLink>Prev</PaginationLink>
                                </Link>
                            </PaginationItem>
                        )}

                        {Array.from({ length: props.context.numberOfPages }, (_, i) => {
                            const active = (props.context.humanPageNumber === i+1 ? true : false);
                            if(active){
                                return(
                                <PaginationItem key={`page${i + 1}`} active>
                                    <Link to={(i === 0 ? (`${props.context.paginationPath}/`) : (`${props.context.paginationPath}/${i+1}`))}>
                                        <PaginationLink>{i + 1}</PaginationLink>
                                    </Link>
                                </PaginationItem>
                                )
                            } else {
                                return(
                                <PaginationItem key={`page${i + 1}`}>
                                    <Link to={(i === 0 ? (`${props.context.paginationPath}/`) : (`${props.context.paginationPath}/${i+1}`))}>
                                        <PaginationLink>{i + 1}</PaginationLink>
                                    </Link>
                                </PaginationItem>
                                )
                            }
                        })}

                        {props.context.nextPagePath && (
                            <PaginationItem>
                            <Link to={`${props.context.nextPagePath}`}>
                                <PaginationLink>Next</PaginationLink>
                            </Link>
                        </PaginationItem>
                        )}
                    </Pagination>

                </Col>          
              </Row>
        </Container>
      )}
    </>
  )
}

export default BlogPagination
