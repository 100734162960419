import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"

// import Tags from "./Tags"

const BlogBox = ({ slug, heroImage, title, publishDate, description, category, ...props }) => {
  return (
    <>
      {heroImage && title ? (
          <Link className="blogCard" to={`${props.basePath}/${slug}/`}>
            <div className="card__img">
                <Image fluid={heroImage.fluid} style={{"height": "100%"}}/>
            </div>
            <div className="card__info">
              <span className="card__category">{category}</span>
              <h2 className="h1 card__title">{title}</h2>
              <h4>READ MORE</h4>
            </div>
          </Link>
      ) : (
        <Link className="blogCard blogCard_text" to={`${props.basePath}/${slug}/`}>
          <div className="card__info card__info_full">
            <span className="card__category">{category}</span>
            <h2 className="h1 card__title">{title}</h2>
              <h4>READ MORE</h4>
          </div>
        </Link>
      )}
    </>
  )
}

export default BlogBox
