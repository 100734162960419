import React from "react"
import { graphql } from "gatsby"

import Card from "../components/Blog/BlogBox"
import Pagination from "../components/Blog/Pagination"

import { Container, Row, Col } from "reactstrap"

import SEO from "../components/SEO"
import FooterEmailSignup from "../components/Footer/FooterEmailSigngup"
import localeContext from "../utils/localeContext"

const Posts = ({ data, pageContext, path }) => {
  const posts = data.allContentfulBlogPost.edges

  // const { humanPageNumber, basePath } = pageContext
  const { basePath } = pageContext
  const { locale } = React.useContext(localeContext);
  const location = (!locale ? "en-GB" : locale)
  // const isFirstPage = humanPageNumber === 1
  // let featuredPost
  // let ogImage

  return (
    <>
      <SEO
        title="brillea Insights"
        pathname={basePath}
      />
      <section className="section section_medium grey_bk text-center">
        <Container>
          <Row>
            <Col className={"text-center"}>
              <h1 className="mb-5">brillea insights</h1>
            </Col>
          </Row>
          {posts.map(({ node: post }) => {
            if(post.node_locale === location){
            return <Card key={post.id} {...post} basePath={basePath} />
            } else {
              return null;
            }
          }
          )}
        </Container>
        <Pagination context={pageContext} />
      </section>
      <FooterEmailSignup />
    </>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          node_locale
          id: contentful_id
          title
          category
          tags
          description {
            description
          }
          slug
          publishDate
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
export default Posts
